import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import("../views/Home.vue")
  },
  {
    path: "/feedback/:eventId/:serviceId",
    name: "feedback",
    component: () =>  import("../views/Feedback.vue")
  },
  {
    path: "/feedback/:eventId",
    name: "feedback",
    component: () =>  import("../views/Feedback.vue")
  },
  {
    path: "/appointment/:eventId",
    name: "confirmation",
    component: () => import("../views/Confirmation.vue")
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
});

// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   if (requiresAuth) {
//   }
//   else next();
// });


export default router
