




























import Vue from "vue";
import {Component} from "vue-property-decorator";

@Component({
  components: {
  }
})
export default class AppLayout extends Vue {
  animation = {
    enter (element: any) {
      /*
       *  "element" - is a notification element
       *    (before animation, meaning that you can take it's initial height, width, color, etc)
       */
      const height = element.clientHeight;

      return {
        // Animates from 0px to "height"
        height: [height, 0],

        // Animates from 0 to random opacity (in range between 0.5 and 1)
        opacity: [Math.random() * 0.5 + 0.5, 0]
      }
    },
    leave: {
      height: 0,
      opacity: 0
    }
  }
}
