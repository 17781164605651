import Vue from 'vue';
import Vuetify from 'vuetify';
import '../../public/css/material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
     iconfont: 'md', // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        // primary: "#AD1457",
        primary: "#A23B6F",
        secondary: "#BCAAA4",
        accent: "#880E4F",
        // error: "#E91E63",
        error: "#d32f2f",
        warning: "#ffeb3b",
        info: "#666666"
      }
    }
  }
});
