export const apiURI = process.env.NODE_ENV === 'production' ? 'https://apinest.anandaspa.us/' : 'https://apinest.anandaspa.us/';
//http://localhost:3000/';

export type ID = string;
export const newID = '';

export type FormModeType = "new" | "edit";

export enum USER_ROLES {
  Administrador = 'Administrador',
  Comercial = 'Comercial',
  Gestor = 'Gestor',
  Observador = 'Observador'
}
export const API_KEY =
  "95wTvKBBjxP0fbyn40qJwIWS3aIaDPI7" +
  "XgoNNq3U41UdR+r3fKhSWmy1MiRZ064Gcx1aJBK0KIswLQZlIAamMRglarXD3a0PvCRxs8yw2DB" +
  "Xvy7kUzlN8OIR7/NFKTh2gZydktfQMw3Mtm4cR3S5r3CHlTbzlhhvmvnuGXeGbd2Vo+lmytdg" +
  "FulZJAg/VaE//VWNEpFlCzMpqy5fEVDEs8Ay/gZlG4XgEZcEPtpZ+CZgNnUFF2Fa2dgblr/GZ8" +
  "v0QqSr7dFLJL6YMbsDUzvzMOZ76CPaLcqW/1C/3Axo+sutp2ZW3QPQpoFkdlpOs9CyJRmj5UYq0UfqVbyPbvBPuQ==";
export enum Sex
{
  Female,
  Male
}
export enum EVENT_STATUS {
  PENDING,
  CONFIRMED,
  CANCELED,
}