import Vue from "vue";
import App from './App.vue';
import Notifications from "vue-notification";
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import {initialize as initializeInversify} from "@/utils/inversify.config";
import {apiURI} from "@/utils/constants";
import 'reflect-metadata';
import '../public/css/material-design-icons-iconfont/dist/material-design-icons.css'
// import Vuetify from 'vuetify'
// import IconBase from '@/components/icons/IconBase.vue'

Vue.config.productionTip = false;
Vue.use(Notifications);
//Vue.use(vuetify, {iconfont: 'md'});
// Vue.component('icon-base', IconBase);

initializeInversify({
  config: {
    baseUrl: apiURI,
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  // async beforeCreate() {
  //   if (this.$store.getters[AuthStoreNames.getters.CONTRACT_TOKEN]) {
  //     await this.$store.dispatch(AuthStoreNames.actions.VERIFY_CONTRACT_TOKEN);
  //   }
  // },
}).$mount('#app');
